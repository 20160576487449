import React, { useContext } from "react";
import "./NavigationLayout.scss";
import { Layout } from "antd";
import { Link, Outlet, useNavigate } from "react-router-dom";
import UserProfile from "components/UserProfile/UserProfile";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import { useIntl } from "react-intl";
import JobsMenuIcon from "assets/icons/job-menu-icon.svg";
import SparkStarIcon from "assets/icons/spark-star.svg";
import ChidoriIconName from "assets/icons/chidori-logo-new-one-line.svg";
import LanguageChanger from "components/LanguageChanger/LanguageChanger";
import { AppContext } from "contexts/AppContext";
import useSignedURL from "hooks/useSignedURL";
import { createSparkHistoryServerLink } from "utils/cluster";
import { useGetClusterInfo } from "hooks/cluster";

const menuItemKey = {
  // OVERVIEW: "overview",
  JOBS: "jobs",
} as const;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const { Content } = Layout;

const NavigationLayout: React.FC = () => {
  const intl = useIntl();

  const navigate = useNavigate();
  const { rootURL } = useContext(AppContext);
  const { isSignedURL } = useSignedURL();
  const { clusterName } = useContext(AppContext);

  const { data: clusterInfo } = useGetClusterInfo({ clusterName });
  const sparkHistoryServerLink = clusterInfo?.Url
    ? createSparkHistoryServerLink(clusterInfo?.Url)
    : "";

  const items: MenuProps["items"] = [
    getItem(
      <label className="navigation-layout__menu-item-wrapper">
        {intl.formatMessage({ id: "sidebarMenu.jobs" })}
      </label>,
      menuItemKey.JOBS,
      <img src={JobsMenuIcon} alt="jobs" />
    ),
  ];
  const onMenuItemClick: MenuProps["onClick"] = (e) => {
    if (e.key === menuItemKey.JOBS && !isSignedURL) {
      navigate(rootURL);
    }
  };

  return (
    <Layout className="navigation-layout__wrapper">
      <Sider
        className="navigation-layout__sider"
        trigger={null}
        collapsible
        collapsed={false}
        theme="light"
        width={256}
      >
        <section className="navigation-layout__sider-logo">
          <Link to={rootURL}>
            <img src={ChidoriIconName} alt="Chidori Icon" />
          </Link>
        </section>
        <Menu
          onClick={onMenuItemClick}
          mode="inline"
          items={items}
          defaultSelectedKeys={[menuItemKey.JOBS]}
          defaultOpenKeys={[menuItemKey.JOBS]}
          className="navigation-layout__menu-wrapper"
        />

        <section className="navigation-layout__sider-actions">
          <section className="navigation-layout__spark-history-link">
            <section>
              <img
                src={SparkStarIcon}
                alt="Spark History Server"
                className="navigation-layout__spark-history-link-icon"
              />
            </section>
            <Link
              to={sparkHistoryServerLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <label className="navigation-layout__spark-history-link-label">
                {intl.formatMessage({ id: "sidebarMenu.sparkHistoryServer" })}
              </label>
            </Link>
          </section>
          <LanguageChanger />
          <UserProfile />
        </section>
      </Sider>
      <Layout className="navigation-layout__main">
        <Content className="navigation-layout__content">
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default NavigationLayout;
