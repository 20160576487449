import React, {
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useState,
  useContext,
} from "react";
import "./JobsChart.scss";
import { init, use, ECharts } from "echarts/core";
import { BarChart, PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
} from "echarts/components";
import { LabelLayout, UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { FormattedMessage, useIntl } from "react-intl";
import {
  millisecondsInHour,
  millisecondsInMinute,
  millisecondsInSecond,
} from "date-fns";
import { adminPathName } from "types/types";
import SpinnerChidori from "components/SpinnerChidori/SpinnerChidori";
import { ECElementEvent } from "echarts/types/dist/shared";
import { Card } from "antd";
import DownloadLogsAction from "components/DownloadLogsAction/DownloadLogsAction";
import SparkLogButton from "components/SparkLogButton/SparkLogButton";
import SignedURLButton from "components/SignedURLButton/SignedURLButton";
import useChidoriRoutes from "hooks/useChidoriRoutes";
import ExternalLinkIcon from "assets/icons/external-link.svg";
import { convertTimestamp } from "utils/date";
import { AppContext } from "contexts/AppContext";
import { JobStatus } from "components/StatusTag/StatusTag";
import { useGetChartJobsData } from "hooks/jobs";
import useSignedURL from "hooks/useSignedURL";

const jobsHistoryBarChartColor = "#8BA3F4";
const jobsHistoryBarChartColorHovered = "#B0C3F8";

function JobsChart({
  tableName,
  schemaName,
  cachedFormatDuration,
}: {
  tableName: string;
  schemaName: string;
  cachedFormatDuration: ({
    duration,
    shorthand,
  }: {
    duration: Duration;
    shorthand?: boolean | undefined;
  }) => string;
}) {
  const intl = useIntl();
  const [selectedBarJobID, setSelectedbarJobID] = useState<string | null>(null);
  const { currentAppliedFilters, isFiltersApplied } = useContext(AppContext);
  const { clusterNameFromURL, isAdmin } = useChidoriRoutes();
  const clusterName = isAdmin ? adminPathName : clusterNameFromURL;
  const { isSignedURL, expires, signature, prefix } = useSignedURL();

  const { data: jobsHistory, isLoading: isJobsHistoryLoading } =
    useGetChartJobsData({
      tableName,
      schemaName,
      clusterName,
      isSignedURL,
      ...(isSignedURL && expires ? { expires } : {}),
      ...(isSignedURL && signature ? { signature } : {}),
      ...(isSignedURL && prefix ? { prefix } : {}),
    });

  const jobInforCardRef = useRef<HTMLDivElement>(null);

  const chartRef = useRef<HTMLDivElement>(null);
  const eChartInstance = useRef<ECharts | null>(null);
  use([
    BarChart,
    PieChart,
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer,
  ]);

  const availableCharts = ["bar", "pie"];
  const [
    selectedChart,
    // setSelectedChart
  ] = useState(availableCharts[0]);

  const cachedFormattedYAxis = useMemo(
    () => jobsHistory?.map((relatedJob) => relatedJob.Duration / 60000),
    [jobsHistory]
  );

  const cachedFormattedData = useMemo(
    () =>
      jobsHistory?.map((relatedJob) => {
        return [
          relatedJob.SparkJobID,
          relatedJob.Duration / millisecondsInMinute,
          relatedJob.Duration,
          relatedJob.CreatedAt,
        ];
      }),
    [jobsHistory]
  );

  const onBarClick = (params: ECElementEvent) => {
    const jobID = (params.data as unknown[])[0] as string;
    setSelectedbarJobID((prev) => {
      const shouldSelect = jobID !== prev;

      eChartInstance.current!.dispatchAction({
        type: shouldSelect ? "select" : "unselect",
        seriesIndex: params.seriesIndex,
        dataIndex: params.dataIndex,
      });
      return shouldSelect ? jobID : null;
    });
  };

  const tooltipFormatter = useCallback((params: any) => {
    let tooltipContent = "";

    for (const key in params) {
      const param = params[key];

      const [jobId, , valueInMSec, createdAt] = param.value;

      const duration = {
        hours: Math.floor((valueInMSec || 0) / millisecondsInHour),
        minutes: Math.floor(
          ((valueInMSec || 0) % millisecondsInHour) / millisecondsInMinute
        ),
        seconds: Math.floor(
          ((valueInMSec || 0) % millisecondsInMinute) / millisecondsInSecond
        ),
      };

      let startDate = "";
      if (createdAt) {
        const { dateFormatted, timeFormatted } = convertTimestamp(
          createdAt ? (createdAt as Date)?.toString() : ""
        );
        startDate = `${dateFormatted}, ${timeFormatted}`;
      }

      tooltipContent += `<section class="job-history__chart-content">      
        <article class="job-history__chart-line table-name">
          <div class="job-history__chart-item-icon"></div>
          <span class="job-history__chart-property-value table-name">
           ${tableName}
         </span>
          </article>

        <article class="job-history__chart-line">
          <span class="job-history__chart-property-name">
            ${intl.formatMessage({ id: "jobsHistoryChart.tooltip.id" })}:
          </span>
          <span class="job-history__chart-property-value">
            ${jobId}          
          </span>
        </article>

        <article class="job-history__chart-line">
          <span class="job-history__chart-property-name">
            ${intl.formatMessage({ id: "jobsHistoryChart.tooltip.start" })}:
          </span>
          <span class="job-history__chart-property-value">
            ${startDate}
          </span>
        </article>

        <article class="job-history__chart-line">
          <span class="job-history__chart-property-name">
            ${intl.formatMessage({ id: "jobsHistoryChart.tooltip.duration" })}:
          </span>
          <span class="job-history__chart-property-value">
            ${cachedFormatDuration({
              duration,
              shorthand: true,
            })}
          </span>
        </article>
      </section>`;
    }

    return tooltipContent;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pieChartTooltipFormatter = useCallback((params: any) => {
    const valueInMSec = params.value;
    const duration = {
      hours: Math.floor((valueInMSec || 0) / millisecondsInHour),
      minutes: Math.floor(
        ((valueInMSec || 0) % millisecondsInHour) / millisecondsInMinute
      ),
      seconds: Math.floor(
        ((valueInMSec || 0) % millisecondsInMinute) / millisecondsInSecond
      ),
    };
    return `${params.name}\n ${cachedFormatDuration({
      duration,
      shorthand: true,
    })} \n (${params.percent}%)`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const seriesCommonConfigs = {
    name: "duration",
    type: selectedChart,
  };

  const [isFiltersAppliedOnChart, setIsFiltersAppliedOnChart] = useState(false);

  useEffect(() => {
    if (jobsHistory) {
      const chartOptions = eChartInstance.current?.getOption();
      if (
        isFiltersApplied ||
        (!isFiltersApplied && isFiltersAppliedOnChart) ||
        !chartOptions ||
        (chartOptions &&
          (chartOptions.series as any[])[0].type !== selectedChart)
      ) {
        eChartInstance.current?.dispose?.();
        eChartInstance.current = init(chartRef.current!);
        eChartInstance.current!.setOption({
          tooltip: {
            trigger: "axis",
            position: function (pt: any) {
              return [pt[0], "10%"];
            },
            formatter: (params: any) => {
              return tooltipFormatter(params);
            },
          },
          ...(selectedChart === "bar" && {
            grid: {
              backgroundColor: "white",
              show: true,
              top: 30,
              bottom: 220,
            },
            xAxis: {
              name: "Jobs",
              type: "category",
              axisLabel: {
                margin: 30,
                interval: 0, // Display all labels
                rotate: 75,
              },
              axisPointer: {
                type: "shadow",
              },
            },
            yAxis: {
              name: "Duration (min)",
              type: "value",
              axisLine: {
                show: true,
              },
              data: cachedFormattedYAxis,
              minInterval: 1,
            },
            series: [
              {
                ...seriesCommonConfigs,
                data: cachedFormattedData,
                barMaxWidth: 50,
                color: jobsHistoryBarChartColor,
                itemStyle: {
                  emphasis: {
                    color: jobsHistoryBarChartColorHovered, // Color for hovered bar
                  },
                },
                selectedMode: "single", // Allow only one bar to be selected at a time
              },
            ],
          }),
          ...(selectedChart === "pie" && {
            legend: {
              top: 45,
              left: "center",
            },
            series: [
              {
                ...seriesCommonConfigs,
                radius: ["50%", "70%"],
                avoidLabelOverlap: false,
                itemStyle: {
                  borderRadius: 10,
                  borderColor: "#fff",
                  borderWidth: 2,
                },
                label: {
                  show: false,
                  position: "center",
                  formatter: function (params: any) {
                    return pieChartTooltipFormatter(params);
                  },
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: 14,
                  },
                },
                data: jobsHistory?.map((relatedJob) => {
                  return {
                    value: relatedJob.Duration,
                    name: relatedJob.SparkJobID,
                  };
                }),
              },
            ],
          }),
        });
        eChartInstance.current.on("click", "series.bar", onBarClick);
        setIsFiltersAppliedOnChart(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobsHistory, selectedChart, currentAppliedFilters]);

  function JobPropertyElement({
    label,
    value,
  }: {
    label: string;
    value: string;
  }) {
    return (
      <section className="job-history__job-info-card-element">
        <article className="job-history__job-info-card-property-name">
          {label}
        </article>
        <article className="job-history__job-info-card-property-value">
          {value}
        </article>
      </section>
    );
  }

  const selectedJob = jobsHistory?.find(
    (job) => job.SparkJobID === selectedBarJobID
  );
  const clusterUrl = selectedJob?.ClusterUrl;
  const clusterPrefix = selectedJob?.ClusterPrefix;
  const jobStatus = selectedJob?.SparkJobStatus;
  const isDownloadLogsBtnDisabled = jobStatus === JobStatus.In_Queue;
  const sparkAppID = selectedJob?.SparkAppID;
  const createdAt = selectedJob?.CreatedAt;
  let startDate;
  if (createdAt) {
    const { dateFormatted, timeFormatted } = convertTimestamp(
      createdAt ? (createdAt as Date)?.toString() : ""
    );
    startDate = `${dateFormatted}, ${timeFormatted}`;
  }
  const durationInMSec = selectedJob?.Duration;
  const duration = {
    hours: Math.floor((durationInMSec || 0) / millisecondsInHour),
    minutes: Math.floor(
      ((durationInMSec || 0) % millisecondsInHour) / millisecondsInMinute
    ),
    seconds: Math.floor(
      ((durationInMSec || 0) % millisecondsInMinute) / millisecondsInSecond
    ),
  };
  const vendor = selectedJob?.Vendor;
  const language = selectedJob?.MVLanguage;

  const showSelectedJobCard =
    selectedBarJobID &&
    clusterPrefix &&
    clusterUrl &&
    jobStatus &&
    sparkAppID &&
    startDate &&
    duration &&
    vendor;

  useEffect(() => {
    if (showSelectedJobCard) {
      jobInforCardRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [showSelectedJobCard]);

  return (
    <div className="job-history__chart-wrapper">
      <h3>
        <FormattedMessage id="jobInfo.jobsDuration" />
      </h3>
      {isJobsHistoryLoading ? (
        <SpinnerChidori />
      ) : (
        <>
          <div className="job-history__chart" ref={chartRef} />
          {showSelectedJobCard && (
            <Card
              size="small"
              title={
                <article className="job-history__job-info-card-title">
                  {`${intl.formatMessage({
                    id: "jobsHistoryChart.card.id",
                  })}: ${selectedBarJobID}`}
                </article>
              }
              className="job-history__job-info-card"
              ref={jobInforCardRef}
            >
              <section className="job-history__job-info-card-body">
                <JobPropertyElement
                  label={intl.formatMessage({
                    id: "jobsHistoryChart.card.start",
                  })}
                  value={startDate || ""}
                />
                <JobPropertyElement
                  label={intl.formatMessage({
                    id: "jobsHistoryChart.card.duration",
                  })}
                  value={cachedFormatDuration({
                    duration,
                    shorthand: true,
                  })}
                />
                <JobPropertyElement
                  label={intl.formatMessage({
                    id: "jobsHistoryChart.card.mV",
                  })}
                  value={tableName}
                />
                <JobPropertyElement
                  label={intl.formatMessage({
                    id: "jobsHistoryChart.card.service",
                  })}
                  value={vendor}
                />
                <JobPropertyElement
                  label={intl.formatMessage({
                    id: "jobsHistoryChart.card.language",
                  })}
                  value={language || ""}
                />
                {/* // */}
              </section>
              <section className="job-history__job-info-card-actions">
                <section className="job-history__job-info-card-action-item download-logs">
                  <DownloadLogsAction
                    jobId={selectedBarJobID}
                    isDisabled={isDownloadLogsBtnDisabled}
                  />
                </section>
                <section className="job-history__job-info-card-action-item">
                  <SignedURLButton
                    clusterName={clusterName}
                    tableName={tableName}
                    jobId={selectedBarJobID}
                    prefix={clusterPrefix}
                    schema={schemaName}
                  />
                </section>
                <section className="job-history__job-info-card-action-item">
                  <SparkLogButton
                    clusterUrl={clusterUrl}
                    jobStatus={jobStatus}
                    sparkAppID={sparkAppID}
                  />
                  <img src={ExternalLinkIcon} alt="External Link" />
                </section>
              </section>
            </Card>
          )}
        </>
      )}
    </div>
  );
}

export default JobsChart;
