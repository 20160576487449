import React from "react";
import "./JobRowInfoCard.scss";
import { CopyOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { copyJobID } from "helpers/clipboard";
import { FormattedMessage } from "react-intl";

function JobRowInfoCard({
  sparkJobID,
  vendor,
  mVLanguage,
  totalExecutorCount = -1,
}: {
  sparkJobID: string;
  vendor: string;
  mVLanguage: string;
  totalExecutorCount?: Number;
}) {
  return (
    <section className="job-row-info-card__icon-wrapper">
      <article className="job-row-info-card__icon-row">
        <span className="job-row-info-card__icon-row-title job-id">
          <span>
            <FormattedMessage id="actions.info.column.jobID" />
          </span>
          <Tooltip
            title={<FormattedMessage id="actions.info.column.jobID.title" />}
          >
            <CopyOutlined
              className="copy-icon"
              onClick={() => copyJobID(sparkJobID)}
            />
          </Tooltip>
        </span>
        <span>{sparkJobID}</span>
      </article>

      <article className="job-row-info-card__icon-row">
        <span className="job-row-info-card__icon-row-title">
          <FormattedMessage id="actions.info.column.service" />
        </span>
        <span>{vendor}</span>
      </article>

      <article className="job-row-info-card__icon-row">
        <span className="job-row-info-card__icon-row-title">
          <FormattedMessage id="actions.info.column.language" />
        </span>
        <span>{mVLanguage}</span>
      </article>
      <article className="job-row-info-card__icon-row">
        <span className="job-row-info-card__icon-row-title">
          <FormattedMessage id="actions.info.column.totalExecutorCount" />
        </span>
        <span>{totalExecutorCount === -1 ? "Executors not found" : totalExecutorCount.toString() }</span>
      </article>
    </section>
  );
}

export default JobRowInfoCard;
