import React, { useContext } from "react";
import "./Header.scss";
import { AppContext } from "contexts/AppContext";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import useSignedURL from "hooks/useSignedURL";

function Header() {
  const { clusterName, pathname, rootURL } = useContext(AppContext);
  const intl = useIntl();
  const tableNameFromURL = pathname.split("/table/")[1];
  const { isSignedURL } = useSignedURL();

  return (
    <header className="header__wrapper">
      <Breadcrumb
        items={[
          {
            title: clusterName ?? "",
          },
          {
            title: !isSignedURL ? (
              <Link to={rootURL} className="header__first-line">
                {intl.formatMessage({
                  id: "header.jobs",
                })}
              </Link>
            ) : (
              intl.formatMessage({
                id: "header.jobs",
              })
            ),
          },
          ...(tableNameFromURL ? [{ title: tableNameFromURL }] : []),
        ]}
      />
      <section className="header__second-line">
        <FormattedMessage id="header.jobsOfCluster" values={{ clusterName }} />
      </section>
    </header>
  );
}

export default Header;
