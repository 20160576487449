import axios from "axios";
import { getClusterTokenFromCookies } from "utils/browserStorage";

export const API_URL = process.env.REACT_APP_API_URL;

export function registerInterceptors() {
  axios.interceptors.request.use(
    async (config) => {
      const isAdmin = window.location.pathname.includes("biga");
      const token = getClusterTokenFromCookies({ isAdmin });
      if (token && !config.url?.includes("/auth")) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
}
