import axios from 'axios';
import { ClusterResponse } from 'types/cluster.type';
import { API_URL } from 'utils/http';

export async function getClusterInfo(clusterName: string) {
  try {
    const res = await axios.get<ClusterResponse>(
      `${API_URL}/cluster?cluster_name=${clusterName}`,
    );
    return res;
  } catch (error) {
    console.error(error);
  }
}
