import React, { useContext, useState } from "react";
import { AxiosError } from "axios";
import "./SignIn.scss";
import { Button, Form, Input, notification } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { Buffer } from "buffer";
import ChidoriIconCircle from "assets/icons/chidori-icon.svg";
import ChidoriIconName from "assets/icons/chidori-logo-new-one-line.svg";
import { useSignIn, useAdminSignIn } from "hooks/user";
import { AppContext } from "contexts/AppContext";
import { adminPathName } from "types/types";
import { useNavigate } from "react-router-dom";
import { setToken } from "utils/browserStorage";
import IncortaWordLogo from "assets/icons/incorta-black-word.svg";
import { format, getYear } from "date-fns";
import { languages } from "enums";

function SignIn() {
  const intl = useIntl();
  const { clusterName, pathname, rootURL } = useContext(AppContext);
  const navigate = useNavigate();

  const { mutateAsync: signInAsync } = useSignIn();
  const { mutateAsync: adminSignInAsync } = useAdminSignIn();

  const [isSignInLoading, setIsSignInLoading] = useState(false);
  const [generalError, setGeneralError] = useState("");

  const { handleChangeLanguage } = useContext(AppContext);

  const LanguagesChoices = (
    <section className="language-choices__wrapper">
      {Object.keys(languages).map((lang: string, i: number) => (
        <section key={lang}>
          <label
            className="language-choices__item"
            onClick={() => {
              const langNow =
                languages[lang] === languages.Japanese
                  ? languages.Japanese
                  : languages.English;
              handleChangeLanguage(langNow);
            }}
          >
            {lang === "Japanese" ? "日本語" : lang}
          </label>
          {i < Object.keys(languages).length - 1 ? " | " : null}
        </section>
      ))}
    </section>
  );

  async function handleAdminSignIn({ basicToken }: { basicToken: string }) {
    try {
      const { data } = await adminSignInAsync({ basicToken });
      setToken({
        clusterName: adminPathName,
        token: data?.token,
      });
      navigate(rootURL);
    } catch (error) {
      notification.error({
        message: "Unsuccessful Sign In",
        description: (error as Error).message,
      });
    }
    setIsSignInLoading(false);
  }

  async function handleClusterSignIn({ basicToken }: { basicToken: string }) {
    if (clusterName) {
      try {
        const { data } = await signInAsync({ clusterName, basicToken });
        setToken({
          clusterName,
          token: data?.token,
        });
        navigate(rootURL);
      } catch (error: unknown) {
        const err = error as AxiosError;
        const message = (err?.response?.data as any)?.message;
        const networkErrorMessage = err?.message;
        const notficationMessage =
          message === "Invalid user credential."
            ? intl.formatMessage({ id: "signIn.invalidUserCredentials" })
            : networkErrorMessage === "Network Error"
            ? intl.formatMessage({ id: "signIn.networkError" })
            : message === "CMC Service is Down"
            ? intl.formatMessage({ id: "signIn.serviceDown" })
            : "Unsuccessful sign in";
        notification.error({
          message: notficationMessage,
          description: (error as Error).message,
        });
      }
    } else {
      notification.error({
        message: "Error",
        description: "No cluster name in url",
      });
    }
    setIsSignInLoading(false);
  }

  const handleFinish = async (values: {
    username: string;
    password: string;
  }) => {
    setGeneralError("");
    setIsSignInLoading(true);

    const basicToken = Buffer.from(
      `${values.username}:${values.password}`
    ).toString("base64");
    if (pathname.includes(adminPathName)) {
      await handleAdminSignIn({ basicToken });
      return;
    } else {
      await handleClusterSignIn({ basicToken });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="cloud__container" data-page="single-sign-on">
      <div className="cloud__side-bg">
        <div className="incorta-logo__wrapper">
          <img
            src={ChidoriIconCircle}
            alt="Chidori Icon"
            className="incorta-logo__circle"
          />
          <img
            src={ChidoriIconName}
            alt="Chidori Icon"
            className="incorta-logo__name"
          />
        </div>
      </div>
      <div className="cloud-main">
        <div className="cloud-main-section">
          <header className="cloud-main__title">
            <FormattedMessage id="signIn.slogan" />
          </header>
          <label className="error-label">{generalError}</label>
          <Form
            name="sign-in"
            onFinish={handleFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <label
              htmlFor="single-sign-on-email"
              className="email-field__label"
            >
              <FormattedMessage id="signIn.label.username" />
            </label>
            <Form.Item
              name="username"
              rules={[{ required: true, message: "Enter a valid username." }]}
            >
              <Input size="large" className="username-input" />
            </Form.Item>

            <label
              htmlFor="single-sign-on-password"
              className="password-field__label"
            >
              <FormattedMessage id="signIn.label.password" />
            </label>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "Empty password field." }]}
            >
              <Input.Password size="large" className="password-input" />
            </Form.Item>

            <Form.Item className="submit-form-button__wrapper">
              <Button
                type="primary"
                htmlType="submit"
                className="submit-form-button"
                loading={isSignInLoading}
              >
                <FormattedMessage id="signIn" />
              </Button>
            </Form.Item>
            {LanguagesChoices}
          </Form>
        </div>
      </div>
      <footer className="sign-in__footer">
        <FormattedMessage
          id="signIn.footer.release"
          values={{
            month: format(new Date(), "MMMM"),
            year: getYear(new Date()),
          }}
        />
        <section className="sign-in__footer-from">
          <FormattedMessage id="signIn.footer.from" />
          <img
            className="sign-in__footer-logo"
            src={IncortaWordLogo}
            alt="Incorta"
          />
        </section>
      </footer>
    </div>
  );
}

export default SignIn;
