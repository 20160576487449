import React, { useCallback, useContext, useEffect, useState } from "react";
import "./JobsTableDetails.scss";
import { AppContext } from "contexts/AppContext";
import { useJobsAndJobsStatuses } from "hooks/jobs";
import {
  JobsResponseItem,
  filtersKeys,
  seachableTablePageSizeOptions,
} from "types/types";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Button,
  Popover,
  Radio,
  RadioChangeEvent,
  Result,
  Tooltip,
} from "antd";
import { AxiosError } from "axios";
import SpinnerChidori from "components/SpinnerChidori/SpinnerChidori";
import {
  BarChartOutlined,
  InfoCircleOutlined,
  LeftOutlined,
  RedoOutlined,
  TableOutlined,
} from "@ant-design/icons";
import {
  format,
  millisecondsInHour,
  millisecondsInMinute,
  millisecondsInSecond,
} from "date-fns";
import SearchableTable, {
  DATA_VIEW,
} from "components/reusables/SearchableTable/SearchableTable";
import { ColumnsType } from "antd/es/table";
import ReactTableDateCellRenderer from "components/JobsTable/TableCellRenderers/DateCellRenderer/DateCellRenderer";
import StatusCellRenderer from "components/JobsTable/TableCellRenderers/StatusCellRenderer/StatusCellRenderer";
import { formatDurationToHMS } from "utils/date";
import { JobStatus } from "components/StatusTag/StatusTag";
import { useLocation, useNavigate } from "react-router-dom";
import useSignedURL from "hooks/useSignedURL";
import JobsChart from "components/JobsTable/JobsChart/JobsChart";
import HoverActions from "../HoverActions/HoverActions";
import JobRowInfoCard from "../JobRowInfoCard/JobRowInfoCard";
import dayjs from "dayjs";
import useChidoriRoutes from "hooks/useChidoriRoutes";
import useAntdSortColumns from "hooks/useAntdSortColumns";
import useAntdTableChange from "hooks/useAntdTableChange";

function JobsTableDetails() {
  const {
    clusterName,
    pathname,
    rootURL,
    currentAppliedFilters,
    setCurrentAppliedFilters,
  } = useContext(AppContext);
  const intl = useIntl();
  const { state } = useLocation();
  const { isSignedURL, schemaFromSignedURL, expires, signature, prefix } =
    useSignedURL();

  const { tableNameFromURL } = useChidoriRoutes();

  const pathParts = pathname.split("/");
  const jobId = state?.jobId ? state?.jobId : pathParts[pathParts.length - 1];

  const schemaName: string = !isSignedURL
    ? state?.currentJobInfo.schemaName
    : schemaFromSignedURL;
  const tableName: string = !isSignedURL
    ? state?.currentJobInfo.tableName
    : tableNameFromURL;

  useEffect(() => {
    if (
      !(filtersKeys.MV in currentAppliedFilters) ||
      !(filtersKeys.SCHEMA in currentAppliedFilters)
    ) {
      setCurrentAppliedFilters({
        MV: [tableName],
        SCHEMA: [schemaName],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const isJobsStatusesEnabled = !!(clusterName && jobId && !isSignedURL);

  const [jobIDFilter, setJobIDFilter] = useState("");
  const [dateRangePicker, setDateRangePicker] = useState<
    dayjs.Dayjs[] | null[]
  >([]);
  const [jobStatusFilters, setJobStatusFilters] = useState<JobStatus[]>([]);

  const [limit, setLimit] = useState(seachableTablePageSizeOptions[0]);
  const [page, setPage] = useState(1);

  const [
    { refetch: refetchJobsStatus },
    {
      data: jobsData,
      isLoading: isJobsLoading,
      error: jobsError,
      refetch: refetchJobs,
      isFetching: isJobsFetching,
      dataUpdatedAt: jobsUpdatedAt,
    },
  ] = useJobsAndJobsStatuses({
    clusterName: clusterName as string,
    isJobsEnabled: true,
    isJobsStatusesEnabled,
    isSignedURL,
    ...(isSignedURL && expires ? { expires } : {}),
    ...(isSignedURL && signature ? { signature } : {}),
    ...(isSignedURL && prefix ? { prefix } : {}),
    pageSize: limit,
    pageNumber: page,
    tableName,
    schemaName,
    ...(Object.keys(currentAppliedFilters)?.length
      ? { filters: currentAppliedFilters }
      : {}),
  });

  async function refetchJobsAndStatuses() {
    refetchJobs();
    refetchJobsStatus();
  }

  const jobs = jobsData?.SparkStatusDto;
  const current = jobsData?.CurrentPage;
  const total = jobsData?.NumberOfJobs;

  const cachedFormatDuration = useCallback(
    ({
      duration,
      shorthand,
      useLeastLetters,
    }: {
      duration: Duration;
      shorthand?: boolean;
      useLeastLetters?: boolean;
    }) => {
      return formatDurationToHMS({
        duration,
        shorthand: shorthand || false,
        useLeastLetters: true,
      });
    },
    []
  );

  const { getSortOptions } = useAntdSortColumns();

  const columns: ColumnsType<JobsResponseItem> = [
    {
      title: <FormattedMessage id="columns.job" />,
      dataIndex: "SparkJobID",
      key: "SparkJobID",
      ...getSortOptions({ fieldName: "SparkJobID" }),
    },
    {
      title: <FormattedMessage id="columns.status" />,
      key: "SparkJobStatus",
      dataIndex: "SparkJobStatus",
      width: "200px",
      render(value, { SparkJobStatus }, index) {
        return <StatusCellRenderer status={SparkJobStatus} />;
      },
    },
    {
      title: () => {
        return (
          <div className="jobs-table__creation-time-column-header">
            <FormattedMessage id="columns.creationTime" />
          </div>
        );
      },
      key: "CreatedAt",
      dataIndex: "CreatedAt",
      width: "250px",
      render(value, { CreatedAt }, index) {
        return (
          <ReactTableDateCellRenderer originalValue={CreatedAt.toString()} />
        );
      },
      ...getSortOptions({ fieldName: "CreatedAt" }),
    },
    {
      title: <FormattedMessage id="columns.duration" />,
      dataIndex: "Duration",
      key: "Duration",
      width: "300px",
      render(
        valueInMSec,
        {
          SparkJobID,
          Vendor,
          MVLanguage,
          ClusterUrl,
          SparkJobStatus,
          SparkAppID,
          ClusterPrefix,
          TotalExecutorCount
        },
        index
      ) {
        const duration = {
          hours: Math.floor((valueInMSec || 0) / millisecondsInHour),
          minutes: Math.floor(
            ((valueInMSec || 0) % millisecondsInHour) / millisecondsInMinute
          ),
          seconds: Math.floor(
            ((valueInMSec || 0) % millisecondsInMinute) / millisecondsInSecond
          ),
        };
        const value = formatDurationToHMS({
          duration,
          shorthand: true,
        });
        const isDownloadLogsBtnDisabled = SparkJobStatus === JobStatus.In_Queue;

        return (
          <span className="jobs-table-details__hoverable-action-cell">
            <section className="jobs-table-details__hoverable-actions">
              <article className="jobs-table-details__cell-value">
                {value}
              </article>
              <HoverActions
                tableName={tableName}
                sparkJobID={SparkJobID}
                isDownloadLogsBtnDisabled={isDownloadLogsBtnDisabled}
                clusterUrl={ClusterUrl}
                sparkJobStatus={SparkJobStatus}
                sparkAppID={SparkAppID}
                clusterName={clusterName}
                schemaName={schemaName}
                clusterPrefix={ClusterPrefix}
              />
            </section>
            <Popover
              placement="top"
              content={
                <JobRowInfoCard
                  sparkJobID={SparkJobID}
                  vendor={Vendor}
                  mVLanguage={MVLanguage}
                  totalExecutorCount={TotalExecutorCount}
                />
              }
              arrow={false}
            >
              <InfoCircleOutlined className="jobs-table-details__actions-info" />
            </Popover>
          </span>
        );
      },
    },
  ];

  const [currentView, setCurrentView] = useState(DATA_VIEW.TABLE_VIEW);
  function onDataViewChange(e: RadioChangeEvent) {
    setCurrentView(e.target.value);
  }

  const { onChange } = useAntdTableChange();

  if (!state && !jobsData && isJobsLoading) {
    return <SpinnerChidori />;
  } else if (!state && !jobsData && jobsError) {
    const err = jobsError as AxiosError;
    const message = (err?.response?.data as any)?.message;
    const errorStatus = err?.response?.status;
    return (
      <Result
        status="error"
        title="Error"
        subTitle={
          errorStatus?.toString().startsWith("4")
            ? message
            : intl.formatMessage({ id: "somethingWentWrong" })
        }
      />
    );
  }
  return (
    <div className="jobs-table-details__wrapper">
      <section className="jobs-table-details__title">
        <article>
          <label className="jobs-table-details__title-table-name">
            {tableName}
          </label>{" "}
          <FormattedMessage id="table.title" />
        </article>
        <section className="jobs-table-details__title-sync">
          <label>
            <FormattedMessage
              id="table.lastSync"
              values={{
                time: jobsUpdatedAt
                  ? format(new Date(jobsUpdatedAt), "hh:mm:ss a")
                  : "",
              }}
            />
          </label>
          <Tooltip title={<FormattedMessage id="table.refreshTitle" />}>
            <Button
              icon={<RedoOutlined rotate={-90} />}
              className="jobs-table-details__title-sync-btn"
              onClick={() => refetchJobsAndStatuses()}
              loading={isJobsFetching}
            />
          </Tooltip>
        </section>
      </section>

      <SearchableTable<JobsResponseItem>
        showAllJobs={false}
        PreContent={
          <section className="jobs-table-details__pre-content">
            <LeftOutlined
              className="jobs-table-details__pre-content-icon"
              onClick={() => {
                if (!isSignedURL) {
                  navigate(rootURL);
                }
              }}
            />
            <article className="jobs-table-details__pre-content-text">
              <span className="jobs-table-details__pre-content-text-bold">
                {tableName}
              </span>{" "}
              <FormattedMessage id="table.tableInSchema" />{" "}
              <span className="jobs-table-details__pre-content-text-bold">
                {schemaName}
              </span>
            </article>
          </section>
        }
        PostContent={
          <Radio.Group
            options={[
              {
                value: DATA_VIEW.CHART_VIEW,
                label: (
                  <Tooltip
                    title={intl.formatMessage({ id: "jobs.view.chart" })}
                  >
                    <BarChartOutlined />
                  </Tooltip>
                ),
              },
              {
                value: DATA_VIEW.TABLE_VIEW,
                label: (
                  <Tooltip
                    title={intl.formatMessage({ id: "jobs.view.table" })}
                  >
                    <TableOutlined />
                  </Tooltip>
                ),
              },
            ]}
            onChange={onDataViewChange}
            value={currentView}
            optionType="button"
            buttonStyle="solid"
          />
        }
        Chart={
          <JobsChart
            tableName={tableName}
            schemaName={schemaName}
            cachedFormatDuration={cachedFormatDuration}
          />
        }
        dataSource={jobs}
        dataSourceTotalCount={total ?? 0}
        columns={columns}
        loading={isJobsLoading}
        pagination={{
          pageSize: limit,
          current,
          total,
          showSizeChanger: true,
          pageSizeOptions: seachableTablePageSizeOptions,
          onShowSizeChange: (_, size) => {
            setLimit(size);
          },
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        rowKey={"SparkJobID"}
        onChange={onChange}
        currentView={currentView}
        jobIDFilter={jobIDFilter}
        setJobIDFilter={setJobIDFilter}
        jobStatusFilters={jobStatusFilters}
        setJobStatusFilters={setJobStatusFilters}
        dateRangePicker={dateRangePicker}
        setDateRangePicker={setDateRangePicker}
      />
    </div>
  );
}

export default JobsTableDetails;
