import React from "react";
import { useLocation } from "react-router-dom";
import { adminPathName } from "types/types";

function useChidoriRoutes() {
  const { pathname } = useLocation();

  const pathParts = pathname.split("/");
  const clusterNameFromURL = pathParts[2];
  const tableNameFromURL = pathname.split("/table/")[1];

  const isAdmin = pathname.includes(adminPathName);

  const rootURLpattern = new RegExp(`^/cluster/${clusterNameFromURL}/?$`);
  const adminRootURLPattern = new RegExp(`^/biga/?$`);
  const isHomePath =
    rootURLpattern.test(pathname) || adminRootURLPattern.test(pathname);

  return { clusterNameFromURL, tableNameFromURL, isAdmin, isHomePath };
}

export default useChidoriRoutes;
