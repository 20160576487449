import React, { createContext, PropsWithChildren, useState } from "react";
import { useLocation } from "react-router-dom";
import english from "locale/en.json";
import japanese from "locale/ja.json";
import { languages } from "../enums";
import {
  adminPathName,
  AppliedFilter,
  AVAILABLE_PATHS,
  ColumnSort,
  filtersKeys,
  pathKeys,
  StatusesCounts,
} from "types/types";
import { useLogOutUser } from "hooks/user";
import Cookies from "js-cookie";
import useSignedURL from "hooks/useSignedURL";
import useChidoriRoutes from "hooks/useChidoriRoutes";

export const AppContext = createContext<{
  currentLocale: Record<string, string>;
  selectedLanguage: string;
  handleChangeLanguage: Function;
  clusterName: string;
  currentAppliedFilters: AppliedFilter;
  setCurrentAppliedFilters: React.Dispatch<React.SetStateAction<AppliedFilter>>;
  isFiltersApplied: boolean;
  sortBy: string | null;
  setSortBy: React.Dispatch<React.SetStateAction<string | null>>;
  sortOrder: ColumnSort;
  setSortOrder: React.Dispatch<React.SetStateAction<ColumnSort>>;
  statusCount: StatusesCounts;
  setStatusCount: React.Dispatch<React.SetStateAction<StatusesCounts>>;
  pathname: string;
  rootURL: string;
}>({
  currentLocale: {},
  selectedLanguage: "",
  handleChangeLanguage: () => {},
  clusterName: "",
  currentAppliedFilters: {},
  setCurrentAppliedFilters: () => {},
  isFiltersApplied: false,
  sortBy: null,
  setSortBy: () => {},
  sortOrder: null,
  setSortOrder: () => {},
  pathname: "/",
  statusCount: {
    all: 0,
    Succeeded: 0,
    Cancelled: 0,
    Failed: 0,
    In_Queue: 0,
    Running: 0,
  },
  setStatusCount: () => {},
  rootURL: "",
});

function AppContextProvider({ children }: PropsWithChildren<any>) {
  const { pathname } = useLocation();
  const { clusterNameFromURL, isAdmin } = useChidoriRoutes();
  const clusterName = isAdmin ? adminPathName : clusterNameFromURL;
  const rootURL = isAdmin
    ? (AVAILABLE_PATHS.get(pathKeys.ALL_CLUSTERS) as string)
    : (AVAILABLE_PATHS.get(pathKeys.ROOT) as string).replace(
        ":clusterName",
        clusterName || ""
      );
  const { logOutUser } = useLogOutUser({ clusterName });

  const { isSignedURL } = useSignedURL();

  const [currentAppliedFilters, setCurrentAppliedFilters] =
    useState<AppliedFilter>({});

  const isFiltersApplied =
    filtersKeys.JOB_ID in currentAppliedFilters ||
    filtersKeys.CREATED_AT_DATE_RANGE in currentAppliedFilters ||
    filtersKeys.STATUS in currentAppliedFilters ||
    filtersKeys.MV in currentAppliedFilters ||
    filtersKeys.SCHEMA in currentAppliedFilters;

  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<ColumnSort>(null);

  const [statusCount, setStatusCount] = useState({
    all: 0,
    Succeeded: 0,
    Cancelled: 0,
    Failed: 0,
    In_Queue: 0,
    Running: 0,
  });
  const [selectedLanguage, setSelectedLanguage] = useState(languages.English);

  const browserLanguage = navigator.language;
  function getInitialLocale(): Record<string, string> {
    switch (browserLanguage.slice(0, 2)) {
      case languages.English:
        return english;
      case languages.Japanese:
        return japanese;
      default:
        return english;
    }
  }
  const [currentLocale, setCurrentLocale] = useState<Record<string, string>>(
    getInitialLocale()
  );

  function handleChangeLanguage(lang: string) {
    if (lang.startsWith(languages.Japanese)) {
      setCurrentLocale(japanese);
      setSelectedLanguage(languages.Japanese);
    } else {
      setCurrentLocale(english);
      setSelectedLanguage(languages.English);
    }
  }

  const storedClusterName = Cookies.get(clusterName);
  if (!storedClusterName && !pathname.includes("/signin") && !isSignedURL) {
    logOutUser();
  }

  return (
    <AppContext.Provider
      value={{
        currentLocale,
        selectedLanguage,
        handleChangeLanguage,
        clusterName,
        currentAppliedFilters,
        setCurrentAppliedFilters,
        isFiltersApplied,
        sortBy,
        setSortBy,
        sortOrder,
        setSortOrder,
        statusCount,
        setStatusCount,
        pathname,
        rootURL,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default AppContextProvider;
