import React, { useContext } from "react";
import "./SearchableTableBar.scss";
import DebouncedInput from "components/reusables/DebouncedInput/DebouncedInput";
import { FormattedMessage, FormattedPlural, useIntl } from "react-intl";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  Loading3QuartersOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { DatePicker, Select, TimeRangePickerProps } from "antd";
import clsx from "clsx";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { JobStatus } from "components/StatusTag/StatusTag";
import { AppContext } from "contexts/AppContext";
import { AppliedFilter, filtersKeys } from "types/types";

type SearchableTableBarProps<T> = {
  showAllJobs: boolean;
  dataSource: readonly T[] | undefined;
  dataSourceTotalCount: number;
  jobIDFilter: string;
  setJobIDFilter: React.Dispatch<React.SetStateAction<string>>;
  jobStatusFilters: JobStatus[];
  setJobStatusFilters: React.Dispatch<React.SetStateAction<JobStatus[]>>;
  dateRangePicker: dayjs.Dayjs[] | null[];
  setDateRangePicker: React.Dispatch<
    React.SetStateAction<dayjs.Dayjs[] | null[]>
  >;
  tableFilter?: string[];
  setTableFilter?: React.Dispatch<React.SetStateAction<string[]>>;
  schemaFilters?: string[];
  setSchemaFilters?: React.Dispatch<React.SetStateAction<string[]>>;
  schemas?: string[];
  tables?: string[];
};
function SearchableTableBar<T extends object>({
  showAllJobs,
  dataSourceTotalCount,
  jobIDFilter,
  setJobIDFilter,
  jobStatusFilters,
  setJobStatusFilters,
  dateRangePicker,
  setDateRangePicker,
  tableFilter,
  setTableFilter,
  schemaFilters,
  setSchemaFilters,
  schemas,
  tables,
}: SearchableTableBarProps<T>) {
  const intl = useIntl();
  const { setCurrentAppliedFilters } = useContext(AppContext);

  const rangePresets: TimeRangePickerProps["presets"] = [
    {
      label: intl.formatMessage({ id: "table.dateRange.today" }),
      value: [dayjs().startOf("day"), dayjs().endOf("day")],
    },
    {
      label: intl.formatMessage({ id: "table.dateRange.past24Hours" }),
      value: [dayjs().add(-1, "d"), dayjs()],
    },
    {
      label: intl.formatMessage({ id: "table.dateRange.past7Days" }),
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: intl.formatMessage({ id: "table.dateRange.past30Days" }),
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: intl.formatMessage({ id: "table.dateRange.past90Days" }),
      value: [dayjs().add(-90, "d"), dayjs()],
    },
    {
      label: intl.formatMessage({ id: "table.dateRange.past12Months" }),
      value: [dayjs().add(-12, "M"), dayjs()],
    },
  ];

  const statusesFilters = [
    {
      label: (
        <section className="searchable-table-bar__status-item">
          <CheckCircleOutlined className="searchable-table-bar__icon--succeeded" />
          <FormattedMessage id="filters.status.succeeded" />
        </section>
      ),
      value: JobStatus.Succeeded,
    },
    {
      label: (
        <section className="searchable-table-bar__status-item">
          <CloseCircleOutlined className="searchable-table-bar__icon--cancelled" />
          <FormattedMessage id="filters.status.cancelled" />
        </section>
      ),
      value: JobStatus.Cancelled,
    },
    {
      label: (
        <section className="searchable-table-bar__status-item">
          <CloseCircleOutlined className="searchable-table-bar__icon--failed" />
          <FormattedMessage id="filters.status.failed" />
        </section>
      ),
      value: JobStatus.Failed,
    },
    {
      label: (
        <section className="searchable-table-bar__status-item">
          <Loading3QuartersOutlined className="searchable-table-bar__icon--in-queue" />
          <FormattedMessage id="filters.status.inQueue" />
        </section>
      ),
      value: JobStatus.In_Queue,
    },
    {
      label: (
        <section className="searchable-table-bar__status-item">
          <Loading3QuartersOutlined className="searchable-table-bar__icon--running" />
          <FormattedMessage id="filters.status.running" />
        </section>
      ),
      value: JobStatus.Running,
    },
  ];

  return (
    <section
      className={clsx(
        "searchable-table-bar__wrapper",
        showAllJobs && "all-jobs"
      )}
    >
      {/* <DebouncedInput
        value={jobIDFilter ?? ""}
        onChange={(value) => {
          setCurrentAppliedFilters((prev) => {
            const filtersOtherThanJobID: AppliedFilter = Object.fromEntries(
              Object.entries(prev).filter(
                ([filterKey]) => filterKey !== filtersKeys.JOB_ID
              )
            );
            const newState = {
              ...filtersOtherThanJobID,
              ...(value
                ? {
                    JOB_ID: value.toString(),
                  }
                : {}),
            };
            return newState;
          });

          setJobIDFilter(String(value));
        }}
        placeholder={intl.formatMessage({ id: "table.search" })}
        prefix={<SearchOutlined />}
      /> */}
      {showAllJobs && (
        <>
          <Select
            showSearch
            className="searchable-table-bar__table-select-wrapper"
            placeholder={
              <FormattedMessage id="table.tableSelect.placeholder" />
            }
            options={tables?.map((table) => ({
              label: table.toLocaleUpperCase(),
              value: table,
            }))}
            maxTagCount="responsive"
            value={tableFilter}
            onChange={(selectedTables) => {
              setCurrentAppliedFilters((appliedFilters) => {
                const filtersOtherThanStatus: AppliedFilter =
                  Object.fromEntries(
                    Object.entries(appliedFilters).filter(
                      ([filterKey]) => filterKey !== filtersKeys.MV
                    )
                  );
                const newState = {
                  ...filtersOtherThanStatus,
                  ...(selectedTables?.length
                    ? {
                        [filtersKeys.MV]: selectedTables,
                      }
                    : {}),
                };
                return newState;
              });
              setTableFilter?.(selectedTables);
            }}
            allowClear
          />
          <Select
            showSearch
            className="searchable-table-bar__schema-select-wrapper"
            placeholder={
              <FormattedMessage id="table.schemaSelect.placeholder" />
            }
            options={schemas?.map((schema) => ({
              label: schema.toLocaleUpperCase(),
              value: schema,
            }))}
            value={schemaFilters}
            onChange={(selectedSchemas) => {
              setCurrentAppliedFilters((appliedFilters) => {
                const filtersOtherThanStatus: AppliedFilter =
                  Object.fromEntries(
                    Object.entries(appliedFilters).filter(
                      ([filterKey]) => filterKey !== filtersKeys.SCHEMA
                    )
                  );
                const newState = {
                  ...filtersOtherThanStatus,
                  ...(selectedSchemas?.length
                    ? {
                        [filtersKeys.SCHEMA]: selectedSchemas,
                      }
                    : {}),
                };
                return newState;
              });
              setSchemaFilters?.(selectedSchemas);
            }}
            allowClear
          />
        </>
      )}
      <DatePicker.RangePicker
        presets={rangePresets}
        value={[dateRangePicker[0], dateRangePicker[1]]}
        onChange={(values: any, [startDate, endDate]) => {
          const isFieldValueSet = !!(startDate && endDate);
          const sameDate = startDate === endDate;

          dayjs.extend(utc);
          dayjs.extend(timezone);

          let fromDate = dayjs();
          let toDate = dayjs();
          let fromDateModifiedISO: string;
          let toDateModifiedISO: string;
          if (isFieldValueSet) {
            const endDateEndOfDay = dayjs(endDate).endOf("day").toDate();
            const startOfSelectedSameDay = dayjs(values[0])
              .startOf("day")
              .toDate();

            const endOfSelectedSameDay = dayjs(values[1]).endOf("day").toDate();

            const originalOffset =
              startOfSelectedSameDay?.getTimezoneOffset() ||
              endOfSelectedSameDay?.getTimezoneOffset() ||
              0;

            fromDate = dayjs(!sameDate ? startDate : startOfSelectedSameDay);
            const fromDateModified =
              originalOffset < 0
                ? fromDate.subtract(originalOffset, "minutes")
                : fromDate.add(originalOffset, "minutes");

            toDate = dayjs(!sameDate ? endDateEndOfDay : endOfSelectedSameDay);
            const toDateModified =
              originalOffset < 0
                ? toDate.subtract(originalOffset, "minutes")
                : toDate.add(originalOffset, "minutes");

            fromDateModifiedISO = fromDateModified.toISOString();
            toDateModifiedISO = toDateModified.toISOString();
          }

          setDateRangePicker(
            isFieldValueSet ? [fromDate, toDate] : [null, null]
          );

          setCurrentAppliedFilters((appliedFilters) => {
            const filtersOtherThanDateRange: AppliedFilter = Object.fromEntries(
              Object.entries(appliedFilters).filter(
                ([filterKey]) => filterKey !== filtersKeys.CREATED_AT_DATE_RANGE
              )
            );
            const newState: AppliedFilter = {
              ...filtersOtherThanDateRange,
              ...(isFieldValueSet
                ? {
                    [filtersKeys.CREATED_AT_DATE_RANGE]: {
                      start_date: fromDateModifiedISO,
                      end_date: toDateModifiedISO,
                    },
                  }
                : {}),
            };
            return newState;
          });
        }}
      />
      <Select
        className="searchable-table-bar__status-select-wrapper"
        placeholder={<FormattedMessage id="table.statusSelect.placeholder" />}
        options={statusesFilters}
        mode="multiple"
        maxTagCount="responsive"
        value={jobStatusFilters}
        onChange={(selectedStatuses) => {
          setJobStatusFilters(selectedStatuses);
          setCurrentAppliedFilters((appliedFilters) => {
            const filtersOtherThanStatus: AppliedFilter = Object.fromEntries(
              Object.entries(appliedFilters).filter(
                ([filterKey]) => filterKey !== filtersKeys.STATUS
              )
            );
            const newState = {
              ...filtersOtherThanStatus,
              ...(selectedStatuses?.length
                ? {
                    [filtersKeys.STATUS]: selectedStatuses,
                  }
                : {}),
            };
            return newState;
          });
        }}
        dropdownRender={(menu) => <>{menu}</>}
      />
      <p className="searchable-table-bar__rows-count">
        {dataSourceTotalCount}{" "}
        <FormattedPlural
          value={dataSourceTotalCount || 0}
          one="row"
          other={<FormattedMessage id="rows" />}
        />
      </p>
    </section>
  );
}

export default SearchableTableBar;
