import { QueryServerKeys } from "QueryServerKeys";
import { useQuery } from "react-query";
import { getClusterInfo } from "services/cluster";

export function useGetClusterInfo({clusterName} : {clusterName: string}){
    const queryOptions = useQuery(QueryServerKeys.CLUSTER.GET_CLUSTER_INFO, 
      async () => {
        const res = await getClusterInfo(clusterName);
        return res?.data
      }
    );
  
    return queryOptions;
  }