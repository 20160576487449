export const QueryServerKeys = {
  SIGN_IN: "sign-in",
  LISTS: {
    GET_SCHEMAS_AND_TABLES: "get-schemas-and-tables",
  },
  JOBS: {
    GET_STATUS_COUNTS: "get-status-counts",
    GET_ALL_JOBS_PAGINATED: "get-all-jobs-paginated",
    GET_TABLE_DETAILS_JOBS_PAGINATED: "get-table-details-jobs-paginated",
    GET_JOB_LOGS: "get-job-logs",
    GET_JOB_SIGNED_URL: "get-job-signed-url",
    GET_JOBS_CHART: "get-jobs-chart",
    GET_JOB_METRICS: "get-job-metrics",
  },
  CLUSTER: {
    GET_CLUSTER_INFO: "get-cluster-info",
  },
};
