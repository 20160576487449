import Cookies from "js-cookie";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { AdminSignIn, SignIn } from "services/user";
import { AVAILABLE_PATHS, adminPathName, pathKeys } from "types/types";
import useChidoriRoutes from "./useChidoriRoutes";
import { useIntl } from "react-intl";
import { AxiosError } from "axios";
import { notification } from "antd";

export function useSignIn() {
  const queryOptions = useMutation(
    async ({
      clusterName,
      basicToken,
    }: {
      clusterName: string;
      basicToken: string;
    }) => {
      return await SignIn({ clusterName, basicToken });
    },
    {
      async onSuccess() {
        // await queryClient.invalidateQueries(QueryServerKeys.USER.GET_USER_DATA);
      },
      onError(error: any) {
        throw error;
      },
    }
  );

  return queryOptions;
}

export function useAdminSignIn() {
  const queryOptions = useMutation(
    async ({ basicToken }: { basicToken: string }) => {
      return await AdminSignIn({ basicToken });
    },
    {
      async onSuccess() {
        // await queryClient.invalidateQueries(QueryServerKeys.USER.GET_USER_DATA);
      },
      onError(error: any) {
        throw new Error(error);
      },
    }
  );

  return queryOptions;
}

export function useLogOutUser({ clusterName }: { clusterName: string }) {
  const navigate = useNavigate();
  const { isAdmin } = useChidoriRoutes();

  function logOutUser() {
    if (Cookies.get(clusterName)) {
      const path = isAdmin ? `/${adminPathName}` : `/cluster/${clusterName}`;
      Cookies.remove(clusterName, { path });
    }
    const signInPageURL = isAdmin
      ? (AVAILABLE_PATHS.get(pathKeys.ALL_CLUSTERS_SIGN_IN) as string)
      : (AVAILABLE_PATHS.get(pathKeys.SIGN_IN) as string).replace(
          ":clusterName",
          clusterName || ""
        );
    navigate(signInPageURL);
  }

  return { logOutUser };
}

export const useOnErrorLogout = function ({
  clusterName,
}: {
  clusterName: string;
}) {
  const intl = useIntl();
  const { logOutUser } = useLogOutUser({ clusterName });
  return {
    onError(error: unknown) {
      const err = error as AxiosError;
      if (
        err.response?.status === 401 &&
        (err.response?.data as any)?.message &&
        /expired|invalid/i.test((err.response?.data as any)?.message)
      ) {
        notification.error({
          message: intl.formatMessage({ id: "error.title" }),
          description: intl.formatMessage({ id: "error.sessionExpired" }),
        });
        logOutUser();
      }
    },
  };
};
