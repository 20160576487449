import { formatDuration, format, parseISO } from "date-fns";

function convertDurationToShortTimeUnits(
  duration: string,
  useLeastLetters: boolean = false
) {
  return duration
    .replace(" seconds", useLeastLetters ? " s" : " sec")
    .replace(" second", useLeastLetters ? " s" : " sec")
    .replace(" minutes", useLeastLetters ? " m" : " mins")
    .replace(" minute", useLeastLetters ? " m" : " min")
    .replace(" hours", useLeastLetters ? " h" : " hrs")
    .replace(" hour", useLeastLetters ? " h" : " hr");
}

export function formatDurationToHMS({
  duration,
  shorthand,
  useLeastLetters = false,
}: {
  duration: Duration;
  shorthand?: boolean;
  useLeastLetters?: boolean;
}) {
  const formattedDuration = formatDuration(duration, {
    format: ["hours", "minutes", "seconds"],
    delimiter: ":",
  });
  if (shorthand) {
    return convertDurationToShortTimeUnits(formattedDuration, useLeastLetters);
  }
  return formattedDuration;
}

export function convertTimestamp(timestamp: string) {
  const parsedDate = parseISO(timestamp);

  const dateFormatted = format(parsedDate, "MMM dd, yyyy");
  const timeFormatted = format(parsedDate, "hh:mm:ss aaa");

  return { dateFormatted, timeFormatted };
}
